exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-articles-2025-01-25-teams-are-better-than-individuals-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/2025-01-25-teams-are-better-than-individuals/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-articles-2025-01-25-teams-are-better-than-individuals-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-articles-2025-01-25-xamarin-support-ends-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/2025-01-25-xamarin-support-ends/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-articles-2025-01-25-xamarin-support-ends-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-articles-5-essential-tips-to-get-your-app-published-on-the-app-store-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/5-essential-tips-to-get-your-app-published-on-the-app-store/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-articles-5-essential-tips-to-get-your-app-published-on-the-app-store-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-articles-are-you-over-testing-your-app-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/are-you-over-testing-your-app/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-articles-are-you-over-testing-your-app-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-articles-how-long-does-mobile-take-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/how-long-does-mobile-take/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-articles-how-long-does-mobile-take-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-articles-should-you-localise-your-app-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/should-you-localise-your-app/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-articles-should-you-localise-your-app-index-mdx" */),
  "component---src-pages-dev-tsx": () => import("./../../../src/pages/dev.tsx" /* webpackChunkName: "component---src-pages-dev-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/termsAndConditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

